<template>
  <div>
    <v-tabs v-model="discoveryTab" background-color="#F5F7FB">
      <!-- <main-topbar :tabs="tabs" /> -->
      <v-tab v-for="item in tabs" :key="item">
        <span class="text-capitalize">{{ item }}</span>
      </v-tab>
    </v-tabs>
    <v-tabs-items v-model="discoveryTab" style="background: transparent">
      <v-tab-item style="background: transparent">
        <div class="mt-3">
          <v-row>
            <v-col cols="3">
              <v-select
                v-model="selectedCountry"
                :items="countries"
                label="Select Country"
                @change="changeCountry"
                :hide-selected="true"
                flat
                solo
                hide-details
                class="shrink"
                :menu-props="{ bottom: true, offsetY: true }"
              >
              </v-select
            ></v-col>
          </v-row>
        </div>
        <discovery-tab :country="selectedCountry" :kenya_forecast="forecast" />
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<script>
// import MainTopbar from "../../../components/Layouts/Topbars/MainTopbar.vue";
import DiscoveryTab from "../../../components/Tabs/DiscoveryTab.vue";

export default {
  data: () => ({
    tabs: ["Sites and Apps"],
    selectedCountry: "kenya",
    discoveryTab: null,
    countries: [
      { text: "Global", value: "global" },
      { text: "Kenya", value: "kenya" },
      { text: "Uganda", value: "uganda" },
      { text: "Ethiopia", value: "ethiopia" },
      { text: "Nigeria", value: "nigeria" },
      { text: "South Africa", value: "south_africa" },
      { text: "Senegal", value: "senegal" },
      { text: "Gabon", value: "gabon" },
      { text: "Angola", value: "angola" },
    ],
  }),
  async mounted() {
    //Opera Forecast
    try {
      this.$store.commit("makingApiRequest", true);
      await this.$store.dispatch("dsp/getAppCountryDiscoveryData", {
        app: "phoenix",
        country: this.selectedCountry,
      });
      this.$store.commit("makingApiRequest", false);
    } catch (error) {
      this.error = error;
    }
  },
  components: {
    DiscoveryTab,
  },
  methods: {
    async changeCountry() {
      try {
        this.$store.commit("makingApiRequest", true);
        await this.$store.dispatch("dsp/getAppCountryDiscoveryData", {
          app: "phoenix",
          country: this.selectedCountry,
        });
        this.$store.commit("makingApiRequest", false);
      } catch (error) {
        this.error = error;
        this.$store.commit("makingApiRequest", false);
      }
    },
  },
  computed: {
    forecast() {
      if (this.selectedCountry == "kenya") {
        return this.$store.state.dsp.phoenix_kenya_discovery_data.forecast;
      } else if (this.selectedCountry == "nigeria") {
        return this.$store.state.dsp.phoenix_nigeria_discovery_data.forecast;
      } else if (this.selectedCountry == "senegal") {
        return this.$store.state.dsp.phoenix_senegal_discovery_data.forecast;
      }else if (this.selectedCountry == "gabon") {
        return this.$store.state.dsp.phoenix_gabon_discovery_data.forecast;
      } else if (this.selectedCountry == "uganda") {
        return this.$store.state.dsp.phoenix_uganda_discovery_data.forecast;
      } else if (this.selectedCountry == "angola") {
        return this.$store.state.dsp.phoenix_angola_discovery_data.forecast;
      } else if (this.selectedCountry == "ethiopia") {
        return this.$store.state.dsp.phoenix_ethiopia_discovery_data.forecast;
      } else if (this.selectedCountry == "south_africa") {
        return this.$store.state.dsp.phoenix_south_africa_discovery_data
          .forecast;
      } else if (this.selectedCountry == "global") {
        return this.$store.state.dsp.phoenix_global_discovery_data.forecast;
      }
      return this.$store.state.dsp.phoenix_kenya_discovery_data.forecast;
    },
  },
};
</script>
